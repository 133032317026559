import React from 'react'
import { Link, graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hat from '../components/hat';
import Story from '../components/story';
import Recipes from '../components/recipes';
import Stores from '../components/stores';

const propTypes = {
  data: PropTypes.object.isRequired,
}

class ProductTemplate extends React.Component {
  render() {
    const {
      title,
      body,
      image,
    } = this.props.data.product;
    const otherProducts = this.props.data.otherProducts.edges.map(edge => edge.node);

    return (
      <Layout>
        <SEO title='Producten' keywords={[`gatsby`, `application`, `react`]} />
        <div className='product'>
          <div className="productContent">
            <h1>{title}</h1>
            <div
              style={{whiteSpace: 'pre-line'}}
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className="productImage">
            {!!image && <Img {...image} />}
          </div>
          <div className="otherProducts">
            <h3>Andere smaken:</h3>
            <ProductList products={otherProducts} />
          </div>
        </div>
        <Hat />
        <Story />
        <Hat />
        <Recipes id='recepten' />
        <Hat />
        <Stores />
      </Layout>
    )
  }
}

const ProductList = ({ products }) => {
  return products.map(product => (
    <p key={product.id}>
      &nbsp;•&nbsp;
      <Link to={`/producten/${product.slug}`}>
        {product.title}
      </Link>
    </p>
  ));
}

ProductTemplate.propTypes = propTypes

export default ProductTemplate

export const pageQuery = graphql`
  query ProductInfo($id: String!) {
    product: contentfulProduct(id: { eq: $id }) {
      id
      slug
      title
      image {
        id
        title
        fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    otherProducts: allContentfulProduct(filter: { id: { ne: $id }}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`
